
import { defineComponent, onMounted, ref } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import axios from "axios";
import { useRoute } from "vue-router";
const listData = ref();
export default defineComponent({
  name: "blog-detail",
  components: {},
  data() {
    return {
      imageUrl: process.env.VUE_APP_IMG_URL,
    };
  },
  setup() {
    const route = useRoute();
    onMounted(() => {
      //   , ['ข่าวสารและบทความ']
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      getDataList();
    });
    const getDataList = () => {
    
      axios
        .get(
          process.env.VUE_APP_API_URL + "/getNews?id=" + route.params.id +"&os=0",
          // "&categories=" +
          // categories.value
          {
            headers: {
              token: localStorage.getItem("id_token") ? localStorage.getItem("id_token") : localStorage.getItem("tokenGuest")
            },
          }
        )
        .then((response) => {
          // console.log(response);
          listData.value = response.data.data.content[0];
          setCurrentPageBreadcrumbs(listData.value.title, ["ข่าวสารและบทความ"]);
        })
        .catch((error) => {
          // console.log(error);
        });
    };

    return {
      getDataList,
      listData,
    };
  },
  methods: {
    setAltImg(event) {
      event.target.src = "media/image-app/no-image.svg";
    },
    removeStyle(html) {
      // const regex = /(style=".+?")/gm;
      // const str = html;
      // const subst = ``;
      // let result = str.replace(regex, subst);
     const result = html.replaceAll("&nbsp;"," ")
      return result;
    },
    formatDate(date) {
      const event = new Date(date);
      return event.toLocaleDateString("th-TH", {
        year: "numeric",
        month: "long",
        day: "numeric",
      });
    },
    formatDateTime(date) {
      const event = new Date(date);
      return (
        event.toLocaleDateString("th-TH", {
          year: "numeric",
          month: "long",
          day: "numeric",
          hour: "2-digit",
          minute: "2-digit",
        }) + " น."
      );
    },
  },
});
