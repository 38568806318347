<template>
  <!--begin::Dashboard-->
  <div class="p-5 container">
    <div class="row mb-5" style="padding: 2vh;">
      <div class="col-12 col-md-6">
        <img
          v-if="listData"
          :src="(listData ? imageUrl + listData.thumbnailsPath : '')"
          @error="setAltImg"
          alt=""
          class="w-100"
          style="border-top-left-radius: 10px;
            border-top-right-radius: 10px;
            object-fit: cover;"
        />
      </div>
      <div class="col-12 col-md-6">
        <p class="text-title" style="font-weight: 700;color: #333333;">
          {{ listData ? listData.title : "" }}
        </p>
        <p class="text-category" style="font-weight: 400;color: #9999D3;">
           {{ formatDate(listData ? listData.createDate : "") }} | {{ listData && listData.categoryProfile.length>0 ? listData.categoryProfile[0][0].data : "" }}
        </p>
        <p class="text-info" style="font-weight: 400; color: #7F7F81!important;"
          v-html="listData ? removeStyle(listData.description) : ''"></p>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent, onMounted, ref } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import axios from "axios";
import { useRoute } from "vue-router";
const listData = ref();
export default defineComponent({
  name: "blog-detail",
  components: {},
  data() {
    return {
      imageUrl: process.env.VUE_APP_IMG_URL,
    };
  },
  setup() {
    const route = useRoute();
    onMounted(() => {
      //   , ['ข่าวสารและบทความ']
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      getDataList();
    });
    const getDataList = () => {
    
      axios
        .get(
          process.env.VUE_APP_API_URL + "/getNews?id=" + route.params.id +"&os=0",
          // "&categories=" +
          // categories.value
          {
            headers: {
              token: localStorage.getItem("id_token") ? localStorage.getItem("id_token") : localStorage.getItem("tokenGuest")
            },
          }
        )
        .then((response) => {
          // console.log(response);
          listData.value = response.data.data.content[0];
          setCurrentPageBreadcrumbs(listData.value.title, ["ข่าวสารและบทความ"]);
        })
        .catch((error) => {
          // console.log(error);
        });
    };

    return {
      getDataList,
      listData,
    };
  },
  methods: {
    setAltImg(event) {
      event.target.src = "media/image-app/no-image.svg";
    },
    removeStyle(html) {
      // const regex = /(style=".+?")/gm;
      // const str = html;
      // const subst = ``;
      // let result = str.replace(regex, subst);
     const result = html.replaceAll("&nbsp;"," ")
      return result;
    },
    formatDate(date) {
      const event = new Date(date);
      return event.toLocaleDateString("th-TH", {
        year: "numeric",
        month: "long",
        day: "numeric",
      });
    },
    formatDateTime(date) {
      const event = new Date(date);
      return (
        event.toLocaleDateString("th-TH", {
          year: "numeric",
          month: "long",
          day: "numeric",
          hour: "2-digit",
          minute: "2-digit",
        }) + " น."
      );
    },
  },
});
</script>
<style>

</style>
